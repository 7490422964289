import React from 'react';
import { withRouter } from 'react-router';
import { ApiPairingService } from '../services/api/index';
import history from './history.util';
import { reverse } from 'named-urls';
import routes from '../routing/routes';
import { ButtonPatientMail } from '../components/buttons';
import { UtilCommon } from './common.util';
import { UtilDevice } from './device.util';

const state = () => UtilCommon.getStore().getState()

const phoneSocket = () => state().settings.phoneSocket ? state().settings.phoneSocket : {};
const selectedDevice = () => connected() ? phoneSocket().selectedDevice : null;
const connected = () => phoneSocket().connected;

const phoneButton = (number) => {
    if (!number) return number;

    if (selectedDevice()) {
        return React.createElement('button', { className: 'btn btn-link p-0 ml-0', onClick: () => call(number) }, [number]);
    }

    if (UtilDevice.isMobile()) {
        return React.createElement('a', { href: 'tel:' + number, className: 'btn btn-link p-0 ml-0' }, [number]);
    }

    return number;
}

const call = (number) => ApiPairingService.call({
    number: number,
    phone: phoneSocket(),
    group: state().group.id
});

const mailButton = (data) => {
    if (!data.patient.email || !data.patient.id) return null;
    return <ButtonPatientMail {...data} />
}

const mail = (data) => { history.push(reverse(routes.patients.mail, data)); };

export const UtilCommunication = withRouter({
    selectedDevice,
    connected,
    phoneButton,
    call,

    mailButton,
    mail,
});