import { reverse } from 'named-urls';
import React from 'react';
import { Button } from 'reactstrap';
import routes from '../../routing/routes';

const ButtonPatientMail = props => (
    <Button color={props.color} type={props.type} size={props.size}
        onClick={() => {
            props.history.push(reverse(routes.patients.mail, { id: props.patient.id, email: props.patient.email }));
            if (props.callback) props.callback();
        }}
        className={'p-0 ml-0 ' + props.className}
        disabled={props.disabled}
        name={props.name}
    >
        {props.patient.email}
    </Button>
)

ButtonPatientMail.defaultProps = {
    type: 'button',
    color: 'link',
    size: 'md',
    name: 'mail',
    disabled: false
}

export default ButtonPatientMail;