import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import { t } from 'i18next';
import { DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown, Button, UncontrolledTooltip } from 'reactstrap';
import { ButtonActionCustom, ButtonActionLink, ModalAppointment, ModalRemoveAppointment } from '../../components';
import moment from 'moment';
import { ApiAppointmentService, ApiOpenAppointmentMomentService } from '../../services/api/index';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import { withRouter } from 'react-router';
import { UtilArray, UtilDate, UtilNotification } from '../../utils';

class OpenAppointmentMomentsidebar extends Component {

    state = {
        loading: true,
        schema: { slots: [] },
        slot: {},
        dropdown: {}
    }

    componentDidMount() {
        this.loadSchedule();
        document.addEventListener('calendar.refreshed', this.bindRefreshedListener);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.settings.selectedCalendarDate !== newProps.selectedCalendarDate) {
            this.loadSchedule();
        }
    }

    componentDidUpdate() {
        this.handleToggle();
    }

    componentWillUnmount() {
        document.removeEventListener('calendar.refreshed', this.bindRefreshedListener);
    }

    render() {
        return (
            <aside className="offsidebar p-2">
                { /* START Off Sidebar (right) */}
                <div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <h3 className="text-center text-thin mt-4">{t('settings.openappointmentmoment:title')}</h3>
                    {this.props.settings.selectedCalendarDate && (
                        <div className='mb-2 d-flex'>
                            <span className='text-muted'>{moment(this.props.settings.selectedCalendarDate).format('dddd DD/MM/YYYY')}</span>

                            <div className='ml-auto'>
                                <span className='fa fa-chevron-left mr-2 text-muted' onClick={() => this.changeDate('prev')}></span>
                                <span className='fa fa-circle mr-2 text-muted' onClick={() => this.changeDate('now')}></span>
                                <span className='fa fa-chevron-right text-muted' onClick={() => this.changeDate('next')}></span>
                            </div>
                        </div>
                    )}

                    {!this.state.loading && (
                        <>
                            <div className='text-center mb-2'>
                                <Button color="success" size="sm" className="w-100"
                                    onClick={() => this.props.history.push(reverse(routes.appointments.open.add, { date: moment(this.props.settings.selectedCalendarDate).format('YYYY-MM-DD'), time: 0, slot: 0, category: 0 }))}
                                >
                                    <span className="fa fa-plus mr-1" aria-hidden></span> {t('common:Add')}
                                </Button>
                            </div>

                            {this.state.schema.slots.map(slot => (
                                <div className="list-group mb-3" key={slot.from}>
                                    <div className="list-group-item">
                                        <div className='d-flex'>
                                            <h4 className="text-muted text-thin d-flex" onClick={() => this.setState({ slot: { [slot.from]: !this.state.slot[slot.from] } })}>
                                                <span className={'mr-2 fa fa-sm fa-chevron-' + (this.state.slot[slot.from] ? 'up' : 'down')}></span>
                                                {moment(slot.from).format('HH:mm')} - {moment(slot.till).format('HH:mm')}
                                                <span className='mr-1'>&nbsp;</span>
                                                <span id={'totals-' + moment(slot.from).format('HH-mm')}>({slot.appointments.claimed.length} / {slot.appointments.queued.length} / {slot.appointments.unqueued.length})</span>

                                                <UncontrolledTooltip target={'totals-' + moment(slot.from).format('HH-mm')}>
                                                    {t('settings.openappointmentmoment:claimed.title')}: {slot.appointments.claimed.length}<br />
                                                    {t('settings.openappointmentmoment:queued.title')}: {slot.appointments.queued.length}<br />
                                                    {t('settings.openappointmentmoment:unqueued.title')}: {slot.appointments.unqueued.length}
                                                </UncontrolledTooltip>
                                            </h4>

                                            <div className='ml-auto'>
                                                <ButtonActionLink icon="plus" size="xs" color="success" tooltip={t('common:Add')}
                                                    to={{ pathname: reverse(routes.appointments.open.add, { date: moment(slot.from).format('YYYY-MM-DD'), time: moment(slot.from).format('HH-mm'), slot: slot.id, category: slot.category.id }) }}
                                                />
                                            </div>
                                        </div>

                                        <div className={!this.state.slot[slot.from] ? 'd-none' : ''}>
                                            {slot.appointments.claimed.length > 0 && (
                                                <>
                                                    <small className="text-muted">{t('settings.openappointmentmoment:claimed.title')}</small>
                                                    {UtilArray.asort(slot.appointments.claimed, 'position').map(appointment => this.renderAppointmentItem(appointment))}
                                                </>
                                            )}
                                            {slot.appointments.queued.length > 0 && (
                                                <>
                                                    <small className="text-muted">{t('settings.openappointmentmoment:queued.title')}</small>
                                                    {UtilArray.asort(slot.appointments.queued, 'position').map(appointment => this.renderAppointmentItem(appointment))}
                                                </>
                                            )}

                                            {slot.appointments.unqueued.length > 0 && (
                                                <>
                                                    <small className="text-muted">{t('settings.openappointmentmoment:unqueued.title')}</small>
                                                    {slot.appointments.unqueued.map(appointment => this.renderAppointmentItem(appointment))}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                { /* END Off Sidebar (right) */}

                <ModalRemoveAppointment
                    removeUrl='client/appointment/appointment/remove-appointment' // API endpoint URL
                    removeRepeatedUrl='client/appointment/appointment/remove-repeated-appointment' // API endpoint URL
                    redirectUrl={this.props.location.pathname}
                    successMessage={t('agenda.appointment:remove.successfull')}
                    text={t('agenda.appointment:remove.message')}
                    onRef={ref => (this._removeModal = ref)}
                    history={this.props.history}
                />
            </aside>
        );
    }

    renderAppointmentItem = (appointment) => (
        <div key={appointment.id} className="list-group-item-action border-0 py-1">
            <div className="media">
                <div className="media-body text-truncate">
                    {!!appointment.position && <span className="badge badge-default mr-1"><strong>{appointment.position}</strong></span>}
                    <Button color="link" onClick={() => this.onDetailsClick(appointment)}>
                        <strong>{appointment.patientName}</strong>
                        {appointment.appointmentData.length > 0 && (
                            <span className='ml-1 text-dark'>
                                <i className='fa fa-info-circle' id={'appointmentdata-' + appointment.id}></i>

                                <UncontrolledTooltip target={'appointmentdata-' + appointment.id}>
                                    {appointment.appointmentData.map(data => (
                                        <div key={data.label}>{data.label}: {data.value}</div>
                                    ))}
                                </UncontrolledTooltip>
                            </span>
                        )}
                    </Button>
                </div>
                <div className="ml-auto">
                    {this.canClaim(appointment) && (<ButtonActionCustom size="xs" icon="plus" color="default" onClick={() => this.onClaimedClick(appointment)} id={'claim' + appointment.id} tooltip={t('settings.openappointmentmoment:action.claim')} />)}
                    {!appointment.position && (<ButtonActionCustom size="xs" icon="arrow-up" color="default" onClick={() => this.onQueueClick(appointment)} id={'claim' + appointment.id} tooltip={t('settings.openappointmentmoment:action.queue')} />)}

                    <UncontrolledButtonDropdown isOpen={this.state.dropdown[appointment.id]} toggle={() => this.setState({ dropdown: { [appointment.id]: !this.state.dropdown[appointment.id] } })} className="d-inline-block mr-md-2" id="btn-openappointmentmoment-select">
                        <DropdownToggle color="default" className="btn-labeled btn-xs">
                            <span><em className="fa fa-ellipsis-v"></em></span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => this.onDetailsClick(appointment)}>
                                <span className='icon-magnifier mr-1'></span> {t('common:action-detail')}
                            </DropdownItem>
                            <DropdownItem onClick={() => this.onEditClick(appointment)}>
                                <span className='fa fa-pencil-alt mr-1'></span> {t('common:action-edit')}
                            </DropdownItem>
                            <DropdownItem onClick={() => this.onPrintClick(appointment)}>
                                <span className='fa fa-print mr-1'></span> {t('common:Print')}
                            </DropdownItem>
                            {appointment.position && !parseInt(appointment.clientId) && (
                                <DropdownItem onClick={() => this.onQueueClick(appointment, false)}>
                                    <span className='fa fa-arrow-down mr-1'></span> {t('settings.openappointmentmoment:action.unqueue')}
                                </DropdownItem>
                            )}
                            <DropdownItem divider />
                            <DropdownItem onClick={() => this.onRemoveClick(appointment)}>
                                <span className='fa fa-trash mr-1'></span> {t('common:action-remove')}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
        </div>
    )

    canClaim = (appointment) => {
        if (!appointment.position || parseInt(appointment.clientId) > 0 || !UtilDate.isToday(moment(appointment.start))) {
            return false;
        }

        if (this.props.location.pathname.indexOf('/agenda/') < 0) {
            return false;
        }

        if (this.props.location.pathname.indexOf('/agenda/client') > -1) {
            return true;
        }

        return this.props.loggedinClient.hasAgenda;
    };

    loadSchedule = () => this.setState({ loading: true }, () =>
        ApiOpenAppointmentMomentService.buildSchedule({ group: this.props.group.id, date: this.props.settings.selectedCalendarDate })
            .then(result => {
                let openSlot = result.slots[0] ? result.slots[0].from : null;
                result.slots.forEach(slot => {
                    if (moment().format('YYYY-MM-DD HH:mm:ss') >= slot.from) {
                        openSlot = slot.from
                    }
                })
                this.setState({ schema: result, slot: { [openSlot]: true } })
            })
            .catch(() => { })
            .then(() => this.setState({ loading: false })));

    changeDate = (action) => {
        let currentDate = moment(this.props.settings.selectedCalendarDate);

        switch (action) {
            case 'next':
                currentDate = currentDate.add(1, 'day');
                break;
            case 'prev':
                currentDate = currentDate.subtract(1, 'day');
                break;
            default:
                currentDate = moment();
                break;
        }

        this.props.actions.changeSetting('selectedCalendarDate', currentDate.format('YYYY-MM-DD'));
    }

    onDetailsClick = (appointment) => ReactDOM.render(<ModalAppointment {...this.props} appointment={appointment} stateSettings={this.props.settings} removeModal={this._removeModal} modalAppointmentCancel={this.modalAppointmentCancel} schemaData={this.state.schemaData} calendarRef={this.calendarRef} onModalRef={ref => (this._modalAppointment = ref)} {...this.props} showCancelButton={this.props.settings.cancelAppointmentsWithReason} parent={this} />, document.getElementById('section-modal'));
    onEditClick = (appointment) => {
        if (parseInt(appointment.clientId)) {
            this.props.history.push(reverse(routes.appointments.edit, { id: appointment.id, client: appointment.clientId }));
        } else {
            this.props.history.push(reverse(routes.appointments.open.edit, { id: appointment.id }));
        }
    }
    onPrintClick = (appointment) => window.open(reverse(routes.appointments.print, { id: appointment.id }));
    onRemoveClick = (appointment) => this._removeModal.fire({ id: appointment.id }, { redirectUrl: this.props.location.pathname });
    onQueueClick = (appointment, doQueue = true) => ApiAppointmentService.quickUpdateOpenAppointment(appointment.id, { update: !doQueue ? 'unqueue' : 'queue' })
        .then(() => UtilNotification.toastSuccess(t('agenda.appointment:edit.successfull')))
        .then(() => this.loadSchedule())
        .catch(error => UtilNotification.toastError(error));
    onClaimedClick = (appointment) => {
        let client = this.props.client.id;
        let pathname = this.props.location.pathname;
        if (pathname.indexOf('/agenda/client/') > -1) {
            pathname = pathname.replace('/agenda/client/', '').split('/');
            client = pathname[0] ? pathname[0] : client;
        } else if (this.props.loggedinClient.hasAgenda) {
            client = this.props.loggedinClient.id;
        }

        ApiAppointmentService.quickUpdateOpenAppointment(appointment.id, { update: 'claimed', newValue: client })
            .then(() => UtilNotification.toastSuccess(t('agenda.appointment:edit.successfull')))
            .then(() => this.props.history.push(this.props.location.pathname))
            .catch(error => UtilNotification.toastError(error))
    };

    close = () => this.props.actions.toggleSetting('openAppointemtnSidebarOpen');

    handleToggle = () => {
        let contentWrapper = document.querySelector('.content-wrapper');
        let body = document.querySelector('body');

        if (this.props.settings.openAppointemtnSidebarOpen && this.isCalendarView()) {
            if (contentWrapper) contentWrapper.classList.add('offsidebar-open');
            if (body) body.classList.add('offsidebar-open');
        } else {

            if (contentWrapper) contentWrapper.classList.remove('offsidebar-open');
            if (body) body.classList.remove('offsidebar-open');
        }
    }

    isCalendarView = () => {
        if (
            this.props.location.pathname.indexOf('/agenda/client') > -1 ||
            this.props.location.pathname.indexOf('/agenda/subgroup') > -1 ||
            this.props.location.pathname.indexOf('/agenda/group') > -1
        ) {
            return true;
        }

        return false;
    }

    bindRefreshedListener = () => this.loadSchedule();
}

const mapStateToProps = state => ({ client: state.client.active, loggedinClient: state.client.logged_in, settings: state.settings, theme: state.theme, group: state.group, schemaData: state.schemaData })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OpenAppointmentMomentsidebar));