import { UtilUrl } from "./url.util";
import routes from '../routing/routes';
import { reverse } from "named-urls";
import { UtilClient } from "./client.util";
import { t } from 'i18next';

const asembleSidebar = (group, loggedinClient = null, notifications = {}) => {
    /**
     * Order links
     * - mijn agenda
     * - (aangemaakte) groepen
     * - alle agenda's (groepsover alg)
     * - huisbezoeken
     * - individuele agenda's
     * - rest
     */
    let menu = [];

    let clientHasRestrictedAccess = UtilClient.hasRestrictedAccess(loggedinClient);
    let clientHasAccessToSettings = UtilClient.hasAccessToSettings();

    menu.push({
        name: 'Patiënten',
        icon: 'icon-people',
        translate: 'sidebar:patients:main',
        path: reverse(routes.patients.overview),
        labels: [
            { key: 'patientConflicts', color: 'warning', tooltip: t('agenda.conflict:title') },
            { key: 'patientsToApprove', color: 'success', tooltip: t('patients.patient:new') },
        ]
    });

    let agendaMenu = {
        name: 'Agenda',
        icon: 'icon-calendar',
        translate: 'sidebar:agenda:main',
        submenu: []
    };

    if (loggedinClient && loggedinClient.hasAgenda) {
        agendaMenu['submenu'].push({
            name: 'Mijn agenda',
            translate: 'sidebar:agenda:myagenda',
            path: reverse(routes.agenda.client)
        });
    }

    agendaMenu['submenu'].push({
        name: 'Zoek vrij tijdstip',
        translate: 'sidebar:agenda:searchfreeslot',
        path: reverse(routes.agenda.searchfreeslot)
    });

    if (group && group.subgroups && group.subgroups.length > 0) {
        group.subgroups.forEach((subgroup) => {
            if (!subgroup.clients || subgroup.clients.length <= 0) {
                return;
            }
            agendaMenu['submenu'].push({
                name: subgroup.name,
                translate: subgroup.name,
                path: reverse(routes.agenda.subgroup, { subgroup: subgroup.id })
            });
        });
    }

    agendaMenu['submenu'].push({
        name: 'Groepsoverzicht',
        translate: 'sidebar:agenda:groupoverview',
        path: reverse(routes.agenda.group)
    });

    if (!clientHasRestrictedAccess) {
        let params = {};
        if (loggedinClient && loggedinClient.hasAgenda) {
            params = { client: loggedinClient.id };
        }
        agendaMenu['submenu'].push({
            name: 'Huisbezoeken',
            translate: 'sidebar:agenda:homevisits',
            path: reverse(routes.homevisits.overview, params)
        });
    }

    if (group && group.clients && group.clients.forEach((client) => {
        if (loggedinClient && loggedinClient.id === client.id) return;
        if (!client.hasAgenda) return;
        if (!UtilClient.hasAccessToAgenda(loggedinClient, client.id)) return;

        agendaMenu['submenu'].push({
            name: client.fullName,
            translate: client.fullName,
            path: reverse(routes.agenda.client, { client: client.id })
        });
    }));

    if (clientHasAccessToSettings) {
        agendaMenu['submenu'].push({
            name: 'Dagafspraken',
            translate: 'sidebar:agenda:dayappointments',
            path: reverse(routes.dayappointments.overview)
        }, {
            name: 'Historiek',
            translate: 'sidebar:agenda:history',
            path: reverse(routes.agenda.history)
        }, {
            name: 'Conflicten',
            translate: 'sidebar:agenda:conflicts',
            path: reverse(routes.agenda.conflicts),
            labels: [
                { key: 'appointmentConflicts', color: 'danger', tooltip: t('agenda.conflict:title') }
            ]
        });
    }

    menu.push(agendaMenu);


    return menu;
};

const asembleSettingSidebar = (group, activeClient = null, loggedInClient = null) => {

    let menu = [];

    if (!activeClient) {
        return menu;
    }

    let hasRestrictedAccess = UtilClient.hasRestrictedAccess(loggedInClient);
    let clientHasAccessToSettings = UtilClient.hasAccessToSettings();

    let returnPath = UtilUrl.buildViewAfterLogin(loggedInClient, group);
    if (!returnPath) {
        returnPath = reverse(routes.agenda.client, { client: activeClient ? activeClient.id : null });
        if (activeClient && !activeClient.hasAgenda) {
            returnPath = reverse(routes.agenda.group)
        }
    }

    menu.push({
        name: 'Terug naar agenda',
        icon: 'fa fa-arrow-left',
        translate: 'sidebar:settings:agenda-back',
        path: returnPath
    });

    if (clientHasAccessToSettings) {
        let settingMenu = {
            name: 'Instellingen',
            icon: 'fa fa-cog',
            translate: 'sidebar:settings:main',
            submenu: []
        };

        settingMenu.submenu.push({
            name: 'forms',
            translate: 'sidebar:settings:forms',
            path: routes.settings.forms.overview
        }, {
            name: 'categories',
            translate: 'sidebar:settings:categories',
            path: routes.settings.categories.overview
        });

        if (activeClient.hasAgenda) {
            settingMenu.submenu.push({
                name: 'profiles',
                translate: 'sidebar:settings:profiles',
                path: routes.settings.schedules.overview
            });
        }

        settingMenu.submenu.push({
            name: 'subcategories',
            translate: 'sidebar:settings:subcategories',
            path: routes.settings.subcategories.overview
        }, {
            name: 'appointmentstatuses',
            translate: 'sidebar:settings:appointmentstatuses',
            path: routes.settings.appointmentstatuses.overview
        }, {
            name: 'holidays',
            translate: 'sidebar:settings:holidays',
            path: reverse(routes.settings.holidays.overview)
        });

        if (activeClient.hasAgenda) {
            settingMenu.submenu.push({
                name: 'exceptions',
                translate: 'sidebar:settings:exceptions',
                path: reverse(routes.settings.exceptions.overview)
            }, {
                name: 'blockings',
                translate: 'sidebar:settings:blockings',
                path: reverse(routes.settings.blocks.overview)
            });
        }

        if (group.settings.openAppointmentMoment && group.settings.openAppointmentMoment.active) {
            settingMenu.submenu.push({
                name: 'openappointmentmoments',
                translate: 'settings.openappointmentmoment:title',
                path: routes.settings.openappointmentmoment.overview
            });
        }

        settingMenu.submenu.push({
            name: 'workspaces',
            translate: 'settings.workspace:title',
            path: routes.settings.workspaces.overview
        }, {
            name: 'tags',
            translate: 'settings.tags:title',
            path: routes.settings.tags.overview
        });
        menu.push(settingMenu);

        let groupMenu = {
            name: 'Praktijk instellingen',
            icon: 'fa fa-users-cog',
            translate: 'sidebar:groupsettings:main',
            submenu: [{
                name: 'group',
                translate: 'sidebar:groupsettings:agenda',
                path: routes.settings.group.preferences
            }]
        };

        if (!hasRestrictedAccess && clientHasAccessToSettings) {
            groupMenu.submenu.push({
                name: 'subgroups',
                translate: 'sidebar:groupsettings:subgroups',
                path: routes.settings.subgroups.overview
            }, {
                name: 'groupsort',
                translate: 'sidebar:groupsettings:sort',
                path: routes.settings.group.sort
            }, {
                name: 'profile',
                translate: 'sidebar:groupsettings:profile',
                path: routes.settings.group.profile
            }, {
                name: 'clients',
                translate: 'sidebar:groupsettings:clients',
                path: routes.settings.group.client.overview
            });

            if (group.settings.paymentModule && group.settings.paymentModule.active) {
                groupMenu.submenu.push({
                    name: 'online-payment',
                    translate: 'sidebar:groupsettings:online-payment',
                    path: routes.settings.payments.links.overview
                });
            }
        }

        menu.push(groupMenu);
    }

    let personalSettingMenu = {
        name: 'Persoonlijke instellingen',
        icon: 'fa fa-user-cog',
        translate: 'sidebar:personalsettings:main',
        submenu: []
    };

    personalSettingMenu.submenu.push({
        name: 'profile',
        translate: 'sidebar:personalsettings:account',
        path: routes.settings.agenda.profile
    }, {
        name: 'authentication',
        translate: 'sidebar:personalsettings:authentication',
        path: routes.settings.agenda.authentication
    });

    if (clientHasAccessToSettings) {
        personalSettingMenu.submenu.push({
            name: 'agenda',
            translate: 'sidebar:personalsettings:agendapreferences',
            path: routes.settings.agenda.preferences
        }, {
            name: 'links',
            translate: 'sidebar:personalsettings:links',
            path: routes.settings.links.overview
        });
    }
    menu.push(personalSettingMenu);

    return menu;
}

const asembleCommunicationSidebar = (group, activeClient = null, loggedInClient = null) => {
    let menu = [];

    if (!activeClient) {
        return menu;
    }

    let returnPath = UtilUrl.buildViewAfterLogin(loggedInClient, group);
    if (!returnPath) {
        returnPath = reverse(routes.agenda.client, { client: activeClient ? activeClient.id : null });
        if (activeClient && !activeClient.hasAgenda) {
            returnPath = reverse(routes.agenda.group)
        }
    }

    menu.push({
        name: 'Terug naar agenda',
        icon: 'fa fa-arrow-left',
        translate: 'sidebar:settings:agenda-back',
        path: returnPath
    });

    let settingMenu = {
        name: 'Communicatie',
        icon: 'fa fa-bell',
        translate: 'sidebar:communication:title',
        submenu: []
    };

    settingMenu.submenu.push({
        name: 'tasks',
        translate: 'sidebar:communication:tasks',
        path: routes.communication.tasks.overview,
        labels: [
            { key: 'pendingTasks', color: 'danger', tooltip: t('tools.task:openTasks') }, { key: 'newTasks', color: 'success', tooltip: t('tools.task:newTasks') }
        ]
    }, {
        name: 'messages',
        translate: 'sidebar:communication:messages',
        path: reverse(routes.communication.mailbox.inbox),
        labels: [
            { key: 'unreadMessages', color: 'success', tooltip: t('profile.mailbox:unread') }
        ]
    }, {
        name: 'mails',
        translate: 'sidebar:communication:mails',
        path: routes.communication.mail.compose
    }, {
        name: 'sms',
        translate: 'sidebar:communication:sms',
        path: routes.communication.sms.compose
    }, {
        name: 'notifications',
        translate: 'sidebar:communication:notifications',
        path: routes.communication.popups.overview
    }, /*{
        name: 'mailing',
        translate: 'sidebar:communication:mailing',
        path: routes.communication.mailing.campaign.overview
    }*/);

    menu.push(settingMenu);

    return menu;
}

export const UtilSidebar = {
    asembleCommunicationSidebar,
    asembleSidebar,
    asembleSettingSidebar
};