import React from 'react';
import { t } from 'i18next';
import { Alert } from 'reactstrap';
import { ApiPatientService } from '../../services/api/index';
import moment from 'moment';
import { BadgeTag } from '../badge';
import ButtonActionCustom from '../buttons/ButtonActionCustom';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import ButtonIcon from '../buttons/ButtonIcon';
import { UtilCommunication } from '../../utils';

class AlertPatientInfoBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patient: null
        };
    }

    componentDidMount() {
        this.loadPatient();
    }

    render() {
        let { patient } = this.state;
        if (!patient) return null;

        return (
            <Alert color="default">
                <div className='d-flex'>
                    <strong className='mr-auto'>Info patiënt</strong>
                    <div>
                        <ButtonActionCustom id="patient-alert-sms" color="default" size="sm" icon="mobile-alt" tooltip={t('patients.patient:action-send-sms')} onClick={() => this.props.history.push(reverse(routes.patients.sms, { id: patient.id }))} />
                        <ButtonActionCustom id="patient-alert-mail" color="default" size="sm" icon="envelope" tooltip={t('patients.patient:action-sendmail')} onClick={() => this.props.history.push(reverse(routes.patients.mail, { id: patient.id }))} />
                        <ButtonActionCustom id="patient-alert-bookappointment" color="default" size="sm" icon="calendar-alt" tooltip={t('common:action-new-appointment')} onClick={this.onNewAppointmentClick}>
                            <span className="ml-1 fa fa-plus"></span>
                        </ButtonActionCustom>

                        <ButtonActionCustom id="patient-alert-upcomingppointments" color="default" size="sm" icon="calendar-alt" tooltip={t('common:action-upcoming-appointments')} onClick={() => this.props.history.push(reverse(routes.patients.appointments, { id: patient.id }))}>
                            ({patient.upcoming})
                        </ButtonActionCustom>
                        <ButtonIcon id="patient-alert-edit" color="info" size="sm" icon="pencil-alt" onClick={() => this.props.history.push(reverse(routes.patients.edit, { id: patient.id }))} tooltip={t('common:action-edit')} />
                    </div>
                </div>
                <div className="alert-content">
                    <div>{t('patients.patient:firstName')}: <br /><strong>{patient.firstName}</strong></div>
                    <div>{t('patients.patient:lastName')}: <br /><strong>{patient.lastName}</strong></div>
                    <div>{t('patients.patient:birthdate')}: <br /><strong>{moment(patient.birthdate).format('DD/MM/YYYY')}</strong></div>
                    <div>{t('patients.patient:phone')}: <br /><strong>{UtilCommunication.phoneButton(patient.phone)}</strong></div>
                    <div>{t('patients.patient:mobile')}: <br /><strong>{UtilCommunication.phoneButton(patient.mobile)}</strong></div>
                    <div>{t('patients.patient:email')}: <br /><strong>{UtilCommunication.mailButton({ ...this.props, patient: patient })}</strong></div>
                </div>

                {patient.tags && (
                    <div className="alert-content">
                        <div>
                            {patient.tags.map(tag => <BadgeTag key={tag.id} tag={tag} />)}
                        </div>
                    </div>
                )}
            </Alert>
        );
    }

    loadPatient = () => ApiPatientService.getPatient(this.props.patientId).then(result => this.setState({ patient: result }));

    onNewAppointmentClick = (e) => {
        let { client } = this.props;
        let { patient } = this.state;

        if (!patient.id || !client || !client.logged_in) return;

        this.props.actions.setPatient({
            id: patient.id,
            firstName: patient.firstName,
            lastName: patient.lastName,
            nickname: patient.nickname,
            birthdate: patient.birthdate,
            email: patient.email,
            phone: patient.phone,
            mobile: patient.mobile,
            gender: patient.gender,
            nationalInsuranceNumber: patient.nationalInsuranceNumber,
            tags: patient.tags,
            smartfield1: patient.smartfield1,
            smartfield2: patient.smartfield2,
            smartfield3: patient.smartfield3,
            smartfield4: patient.smartfield4,
            smartfield5: patient.smartfield5,
            street: patient.street,
            number: patient.number,
            box: patient.box,
            postalCode: patient.postalCode,
            city: patient.city,
            country: patient.country,
            nationality: patient.nationality,
            info: patient.info
        });

        client = client.logged_in;

        if (!client.hasAgenda) {
            this.props.history.push(reverse(routes.agenda.group));
        } else {
            this.props.history.push(reverse(routes.agenda.client));
        }
    }
}

const mapStateToProps = state => ({ client: state.client });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AlertPatientInfoBlock));